@import "../../style/colores";

.logoNavegador {
  width: 100px;
  // padding: 15% 0px 15% 0px;
}

.navContainerButton {
  margin-right: 10%;
}

.containerNav {
  background-image: url("../../assets/fondo.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.navName {
  font-family: "Lamero";
  margin-right: 0px;
  font-size: 3em;
  padding-right: 5px;
}

.toggleBtnConfig {
  background-color: transparent;
}

.imgButton {
  width: 25px;
  margin-left: 50%;
}

.containerNavLeft {
  width: 100%;
}

.IconoBtnNavLeft {
  width: 35px;
  margin-right: 10px;
}

.BtnNavLeft {
  font-size: large;
  font-weight: lighter;
  padding: 25px;
  color: gray;
}

.BtnNavLeftSelect {
  font-size: large;
  color: $fucsia;
}

.BtnNavLeftSelect {
  margin: 25px;
}

.badgeEstablecimiento {
  padding: 10px;
  margin: 5px;
  font-size: medium;
  text-transform: uppercase;
}
