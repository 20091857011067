$fucsia: #ff00e1;
$grisClaro: #b2b2b2;
$grisOscuro: #878787;
$naranja: #ffa300;
$amarillo: #fff200;
$verdeAgua: #00ff91;
$violeta: #d720f2;
$fucsiaSec: #ff00a4;


//FONDOS LISTAS
$grisOscuroFondo: #ededed;
$fucsiaFondo:#fcc3fc;
$grisClaroFondo:#fafafa;
