@import "../../style/colores";

.containerListaInsumos {
  padding: 15px;
}

.titleCardInsumo {
  background-color: $fucsia;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: normal;
  padding: 5px 0px 5px 0px;
}

.badgeCategoria {
  background-color: $fucsia;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: normal;
  padding: 5px;
  text-transform: uppercase;
}
