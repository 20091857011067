@import "../../style/colores";

.contenedorInicio {
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/fondo.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardLogin {
  width: 20rem;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoFormLogin {
  width: 100px;
  padding: 15% 0px 15% 0px;
}

.iconoFormInput {
  width: 20px;
}

.inputLogin {
  margin: 0px 0px 10px 10px;
  border-radius: 0px;
  border-width: 0px 0px 1px 0px;
}
.inputLogin:focus {
  border-width: 0px 0px 1px 0px;
  border-radius: 0px;
}

.containerInputLogin {
  padding: 5% 0px 5% 0px;
  display: flex;
  flex-direction: row;
}
