@import "_colores";
@import "_fuentes";
@import "../layout/inicio/inicio.scss";
@import "_custom.scss";

//TEMA

//COLORES

//BUTTONS

//LISTAS

.headerLista{
	font-weight: lighter;
}

.titleCategoriaLista{
	background-color: $fucsiaFondo;
	color: $fucsiaSec;
	font-weight: bold;
	border-radius: 5px;
	
}

.titleNombreLista{
	background-color: $grisOscuroFondo;
	font-weight: bold;
	border-radius: 5px;

}

.celdaLista{
	background-color: $grisClaroFondo ;
	font-weight: lighter;
	border-radius: 5px;
	color: $grisClaro;
}


//TITULOS

.titleLista {
	text-transform: uppercase;
	color: $grisClaro;
	font-weight: bold;
}

.subtitleLista {
	color: $grisClaro;
}

.badgePrimary {
	background-color: $fucsia;
	border-radius: 5px;
	text-align: center;
	color: white;
	font-weight: normal;
	padding: 5px;
	text-transform: uppercase;
}
.badgeLightGreen {
	background-color: $verdeAgua;
	border-radius: 5px;
	text-align: center;
	color: white;
	font-weight: bold;
	padding: 5px;
	text-transform: uppercase;
}
.badgeLightGray {
	background-color: $grisClaro;
	border-radius: 5px;
	text-align: center;
	color: white;
	font-weight: bold;
	padding: 5px;
	text-transform: uppercase;
}
.badgeGray {
	background-color: $grisOscuro;
	border-radius: 5px;
	text-align: center;
	color: white;
	font-weight: bold;
	padding: 5px;
	text-transform: uppercase;
}
