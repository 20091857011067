@import "../../style/colores";

.containerListaProductos {
  padding: 15px;
}

.titleCardProducto {
  background-color: $fucsia;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: normal;
  padding: 5px 0px 5px 0px;
}

.lineas {
  padding: 2.5px 0px 2.5px 0px;
}

.cardFileSelector > input[type='file'] {
	opacity: 0 !important;
	display: none !important;
}

.iconButtonAgregar {
	color: lightgreen;
	transition: 0.25s;
}

.iconButtonAgregar:hover {
	color: green;
}

.cardTitle {
	text-align: center;
	font-weight: bold;
	color: white;
}
.cardDescripcion {
	font-weight: lighter;
	width: 100%;
	border: none;
}
